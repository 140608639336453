<script setup lang="ts">
const { isHelpSlideoverOpen } = useDashboard()
const { metaSymbol } = useShortcuts()

const shortcuts = ref(false)
const query = ref('')

const links = [{
	label: 'Atalhos',
	icon: 'i-heroicons-key',
	trailingIcon: 'i-heroicons-arrow-right-20-solid',
	color: 'gray',
	onClick: () => {
		shortcuts.value = true
	}
}, {
	label: 'Documentação',
	icon: 'i-heroicons-book-open',
	to: '/docs',
	target: '_blank'
}]

const categories = computed(() => [{
	title: 'Configurações',
	items: [
		{ shortcuts: [metaSymbol.value, 'K'], name: 'Menu de comando' },
		{ shortcuts: ['N'], name: 'Notificações' },
		{ shortcuts: ['?'], name: 'Ajuda & Suporte' },
		{ shortcuts: ['/'], name: 'Procurar' }
	]
}, {
	title: 'Navegação',
	items: [
		{ shortcuts: ['G', 'H'], name: 'Ir para o Dashboard' },
		{ shortcuts: ['G', 'S'], name: 'Ir para Configurações' },
		{ shortcuts: ['Shift', 'I'], name: 'Ir para Cadastrar Proposta' },
		{ shortcuts: ['Shift', 'A'], name: 'Ir para Esteira de Produção' },
		{ shortcuts: ['Shift', 'C'], name: 'Ir para Convenios' },
		{ shortcuts: ['Shift', 'R'], name: 'Ir para Roteiros Operacionais' },
		{ shortcuts: ['Shift', 'B'], name: 'Ir para Bancos' },
		{ shortcuts: ['Shift', 'T'], name: 'Ir para Produtos' },
		{ shortcuts: ['Shift', 'X'], name: 'Ir para Acessos' }
	]
}])

const filteredCategories = computed(() => {
	return categories.value.map(category => ({
		title: category.title,
		items: category.items.filter((item) => {
			return item.name.search(new RegExp(query.value, 'i')) !== -1
		})
	})).filter(category => !!category.items.length)
})
</script>

<template>
	<UDashboardSlideover v-model="isHelpSlideoverOpen">
		<template #title>
			<UButton
				v-if="shortcuts"
				color="gray"
				variant="ghost"
				size="sm"
				icon="i-heroicons-arrow-left-20-solid"
				@click="shortcuts = false"
			/>

			{{ shortcuts ? 'Atalhos' : 'Ajuda & Suporte' }}
		</template>

		<div
			v-if="shortcuts"
			class="space-y-6"
		>
			<UInput
				v-model="query"
				icon="i-heroicons-magnifying-glass"
				placeholder="Procurar..."
				autofocus
				color="gray"
			/>

			<div
				v-for="(category, index) in filteredCategories"
				:key="index"
			>
				<p class="mb-3 text-sm text-gray-900 dark:text-white font-semibold">
					{{ category.title }}
				</p>

				<div class="space-y-2">
					<div
						v-for="(item, i) in category.items"
						:key="i"
						class="flex items-center justify-between"
					>
						<span class="text-sm text-gray-500 dark:text-gray-400">{{ item.name }}</span>

						<div class="flex items-center justify-end flex-shrink-0 gap-0.5">
							<UKbd
								v-for="(shortcut, j) in item.shortcuts"
								:key="j"
							>
								{{ shortcut }}
							</UKbd>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="flex flex-col gap-y-3"
		>
			<UButton
				v-for="(link, index) in links"
				:key="index"
				:color="link.color"
				v-bind="link"
			/>
		</div>
	</UDashboardSlideover>
</template>
